import axios from 'axios'

export default async function acciones() {
  return 0
}

export async function fetchAcciones({ commit }, idCurso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `trabajo/${idCurso}`,
    })
    commit('setAcciones', data[0])
    let params = ''
    if (data[0]) {
      params = data[0]
    } else {
      params = {
        id: null,
        profesores: '',
        asistentes: '',
        estudiantes: '',
        familia: '',
        comunidad: '',
        observaciones: '',
        id_curso: null,
      }
    }
    return { status: 'success', data: params }
  } catch (e) {
    commit('accionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function addAccion({ commit }, accion) {
  try {
    commit('accionErrorNull')
    await axios({
      method: 'POST',
      url: 'trabajo',
      data: {
        profesores: accion.profesores,
        asistentes: accion.asistentes,
        estudiantes: accion.estudiantes,
        familia: accion.familia,
        comunidad: accion.comunidad,
        observaciones: accion.observaciones,
        id_curso: accion.idCurso,
      },
    })
    return { status: 'success' }
  } catch (e) {
    commit('accionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function updateAccion({ commit }, accion) {
  try {
    commit('accionErrorNull')
    await axios({
      method: 'PUT',
      url: `trabajo/${accion.id}`,
      data: {
        profesores: accion.profesores,
        asistentes: accion.asistentes,
        estudiantes: accion.estudiantes,
        familia: accion.familia,
        comunidad: accion.comunidad,
        observaciones: accion.observaciones,
        id_curso: accion.idCurso,
      },
    })
    return { status: 'success' }
  } catch (e) {
    commit('accionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}
