import axios from 'axios'

export default async function establecimientos() {
  return 0
}

export async function fetchEstablecimiento({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `establecimiento/${id_establecimiento}`,
    })
    commit('setEstablecimiento', data)
    return { state: 'success', data: data[0] }
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function fetchEstablecimientos({ commit }) {
  try {
    const { data } = await axios.get('establecimiento')
    commit('setEstablecimientos', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function fetchValidacionesStandar({ commit }) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: 'standard',
    })
    return { state: 'success', data }
  } catch (e) {
    commit('establecimientoError', e.response.data)
    return { state: 'error', message: e.response.data }
  }
}

export async function addEstablecimiento({ commit }, establecimiento) {
  try {
    commit('establecimientoErrorNull')
    await axios({
      method: 'POST',
      url: 'establecimiento',
      data: {
        insignia: establecimiento.insignia,
        rbd: establecimiento.rbd,
        nombre: establecimiento.nombre,
        abreviatura: establecimiento.abreviatura,
        nombre_calle: establecimiento.nombre_calle,
        numero_calle: establecimiento.numero,
        celular: establecimiento.celular,
        correo: establecimiento.correo,
        estado: 'Activo',
      },
    })
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateEstablecimiento({ commit }, establecimiento) {
  try {
    commit('establecimientoErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `establecimiento/${establecimiento.id}`,
      data: {
        rbd: establecimiento.rbd,
        nombre: establecimiento.nombre,
        abreviatura: establecimiento.abreviatura,
        nombre_calle: establecimiento.nombre_calle,
        numero_calle: establecimiento.numero,
        celular: establecimiento.celular,
        correo: establecimiento.correo,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function fetchAproximaciones({ commit }, id_establecimiento) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `configuracion/${id_establecimiento}`,
    })
    commit('setAproximaciones', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function addAproximaciones({ commit }, configuracion) {
  try {
    commit('establecimientoErrorNull')

    const { data } = await axios({
      method: 'POST',
      url: 'configuracion',
      data: {
        aproximar_asignatura: configuracion.aproximar_asignatura,
        aproximar_periodo: configuracion.aproximar_periodo,
        aproximar_asignatura_anual: configuracion.aproximar_asignatura_anual,
        aproximar_final: configuracion.aproximar_final,
        decimas: configuracion.decimas,
        jec: configuracion.jec,
        tipo_evaluaciones: configuracion.tipo_evaluaciones,
        id_decreto: configuracion.id_decreto,
        id_calendario_escolar: configuracion.id_calendario_escolar,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateAproximaciones({ commit }, configuracion) {
  try {
    commit('establecimientoErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `configuracion/${configuracion.id}`,
      data: {
        aproximar_asignatura: configuracion.aproximar_asignatura,
        aproximar_periodo: configuracion.aproximar_periodo,
        aproximar_asignatura_anual: configuracion.aproximar_asignatura_anual,
        aproximar_final: configuracion.aproximar_final,
        decimas: configuracion.decimas,
        id_decreto: configuracion.id_decreto,
        tipo_evaluaciones: configuracion.tipo_evaluaciones,
        jec: configuracion.jec,
        id_calendario_escolar: configuracion.id_calendario_escolar,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateEstablecimientoPeriodo({ commit }, establecimiento) {
  try {
    commit('establecimientoErrorNull')
    const fecha = new Date()
    const dia = fecha.getDate()
    const mes = (fecha.getMonth() + 1)
    const year = fecha.getFullYear()
    const fechaInicioPeriodoActivo = `${year}-${mes}-${dia}`
    await axios({
      method: 'PUT',
      url: `/establecimientos/periodoActivo/${establecimiento.id}`,
      data: {
        idPeriodoActivo: establecimiento.idPeriodoActivo,
        fechaInicioPeriodoActivo,
      },
    })

  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function updateEstablecimientoEstado({ commit, dispatch }, establecimiento) {
  let est = ''
  try {
    commit('establecimientoErrorNull')
    if (establecimiento.estado === 'Inactivo') {
      est = 'Activo'
    } else if (establecimiento.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/establecimientos/${establecimiento.id}`,
      data: {
        nombre: establecimiento.nombre,
        estado: est,
      },
    })
    // actualizamos lista de establecimientos
    dispatch('fetchEstablecimientos')
    dispatch('fetchEstablecimientosActivas')
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}

export async function removeEstablecimientos({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/establecimientos/${id}`,
    })
    // actualizamos lista de establecimientos
    dispatch('fetchEstablecimientos')
  } catch (e) {
    commit('establecimientoError', e.response.data)
  }
}
