import axios from 'axios'

export default async function aplicaciones() {
  return 0
}

export async function fetchAplicaciones({ commit }, idCurso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `registroimplementacion/${idCurso}`,
    })
    commit('setAplicaciones', data)
    return { status: 'success', data }
  } catch (e) {
    commit('aplicacionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function addAplicacion({ commit }, aplicacion) {
  try {
    commit('aplicacionErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'registroimplementacion',
      data: {
        acciones: aplicacion.acciones,
        evaluacion: aplicacion.evaluacion,
        id_detalle_estrategia: aplicacion.id_detalle_estrategia,
      },
    })
    commit('setResponse', data)
    return { ...data }
  } catch (e) {
    commit('aplicacionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function updateAplicacion({ commit }, aplicacion) {
  try {
    commit('aplicacionErrorNull')
    const {data} = await axios({
      method: 'PUT',
      url: `registroimplementacion/${aplicacion.id}`,
      data: {
        acciones: aplicacion.acciones,
        evaluacion: aplicacion.evaluacion,
        id_detalle_estrategia: aplicacion.id_detalle_estrategia,
      },
    })
    commit('setResponse', data)
    return { ...data }
  } catch (e) {
    commit('aplicacionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function removeAplicacion({ commit, dispatch }, aplicacion) {
  try {
    await axios({
      method: 'DELETE',
      url: `/registroimplementacion/${aplicacion.id}`,
    })
    // actualizamos lista de aplicaciones
    dispatch('fetchAplicaciones', aplicacion.id_curso)
    return { status: 'success' }
  } catch (e) {
    commit('aplicacionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}
