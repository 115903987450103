import axios from 'axios'

export default async function planApoyo() {
  return 0
}

export async function fetchPlanApoyos({ commit }, idCurso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `planapoyo/${idCurso}`,
    })
    commit('setPlanApoyo', data)
    return { status: 'success', data }
  } catch (e) {
    commit('planApoyoError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function fetchPlanApoyosAlumnos({ commit }, idCurso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `planapoyoalumno/${idCurso}`,
    })
    commit('setPlanApoyoAlumnos', data)
    return { status: 'success', data }
  } catch (e) {
    commit('planApoyoError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function fetchPlanApoyoHorarios({ commit }, idCurso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `planapoyohorario/${idCurso}`,
    })
    commit('setPlanApoyoHorarios', data)
  } catch (e) {
    commit('planApoyoError', e.response.data)
  }
}

export async function fetchPlanApoyoHorario({ commit }, idHorario) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `planapoyohorarioespecifico/${idHorario}`,
    })
    commit('setPlanApoyoHorario', data[0])
  } catch (e) {
    commit('planApoyoError', e.response.data)
  }
}


export async function addPlanApoyo({ dispatch }, planApoyo) {
  try {
    const {data} = await axios({
      method: 'POST',
      url: 'planapoyo',
      data: { ...planApoyo },
    })
    await dispatch('fetchPlanApoyos', planApoyo.id_curso);
    return { ...data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function addPlanApoyoHorario({ commit }, planApoyoHorario) {
  try {
    commit('planApoyoErrorNull')
    const {data} = await axios({
      method: 'POST',
      url: 'planapoyohorario',
      data: {
        dia: planApoyoHorario.dia,
        hora_inicio: planApoyoHorario.hora_inicio,
        hora_termino: planApoyoHorario.hora_termino,
        id_plan_apoyo_persona: planApoyoHorario.id_plan_apoyo_persona,
      },
    })
    commit('setResponseHorario', data)
  } catch (e) {
    commit('planApoyoError', e.response.data)
  }
}

export async function updatePlanApoyo({ dispatch }, planApoyo) {
  try {
    const {data} = await axios({
      method: 'PUT',
      url: `planapoyo/${planApoyo.id}`,
      data: { ...planApoyo },
    })
    await dispatch('fetchPlanApoyos', planApoyo.id_curso);
    return { ...data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function updatePlanApoyoHorario({ commit }, planApoyoHorario) {
  try {
    commit('planApoyoErrorNull')
    const {data} = await axios({
      method: 'PUT',
      url: `planapoyohorario/${planApoyoHorario.id}`,
      data: {
        dia: planApoyoHorario.dia,
        horas_inicio: planApoyoHorario.horas_inicio,
        hora_termino: planApoyoHorario.hora_termino,
        id_plan_apoyo: planApoyoHorario.id_plan_apoyo,
      },
    })

    if (data.message === 'Registro no encontrado') {
      dispatch('addPlanApoyoDetalle', planApoyoDetalle)
    }

  } catch (e) {
    commit('planApoyoError', e.response.data)
  }
}

export async function removePlanApoyo({ commit, dispatch }, planApoyo) {
  try {
    await axios({
      method: 'DELETE',
      url: `/planapoyo/${planApoyo.id}`,
    })
    // actualizamos lista de planApoyo
    dispatch('fetchPlanApoyo', planApoyo.id_curso)
  } catch (e) {
    commit('planApoyoError', e.response.data)
  }
}

export async function removePlanApoyoHorario({ commit, dispatch }, planApoyoHorario) {
  try {
    await axios({
      method: 'DELETE',
      url: `/planapoyohorario/${planApoyoHorario.id}`,
    })
    // actualizamos lista de planApoyoHorario
    dispatch('fetchPlanApoyo', planApoyoHorario.id_curso)
  } catch (e) {
    commit('planApoyoError', e.response.data)
  }
}
