import axios from 'axios'

export default async function asignaturas() {
  return 0
}

export async function fetchAsignaturas({ commit }) {
  commit('SET_LOADING', true)
  try {
    const { data } = await axios.get('asignatura')
    commit('setAsignaturas', data)
    commit('SET_LOADING', false)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function fetchAsignaturasCurso({ commit }, id_curso) {
  commit('SET_LOADING', true)
  try {
    const { data } = await axios({
      method: 'GET',
      url: `asignaturas/${id_curso}`,
    })
    commit('setAsignaturasCurso', data)
    commit('SET_LOADING', false)
    return { status: 'success', data }
  } catch (e) {
    commit('asignaturaError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function fetchAsignaturasDocente({ }, params) {
  try {
    const { id_curso, id_persona_rol } = params
    const { data } = await axios({
      method: 'GET',
      url: `asignaturas/docente/${id_curso}/${id_persona_rol}`,
    })
    return { status: 'success', data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}



export async function createAsignatura({ commit }, asignatura) {
  try {
    commit('asignaturaErrorNull')

    const { data } = await axios({
      method: 'POST',
      url: 'asignatura',
      data: {
        nombre: asignatura.nombre,
        tipo: asignatura.tipo,
        orden: asignatura.orden,
        promedio: asignatura.promedio,
        prioritaria: asignatura.prioritaria,
        id_estado: asignatura.id_estado,
        horas: asignatura.horas,
        horas_asignadas: asignatura.horas_asignadas,
        id_curso: asignatura.id_curso,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function updateAsignatura({ commit }, asignatura) {
  try {
    commit('asignaturaErrorNull')
    const { data } = await axios({
      method: 'PUT',
      url: `asignatura/${asignatura.id}`,
      data: {
        nombre: asignatura.nombre,
        tipo: asignatura.tipo,
        orden: asignatura.orden,
        promedio: asignatura.promedio,
        prioritaria: asignatura.prioritaria,
        id_estado: asignatura.id_estado,
        horas: asignatura.horas,
        horas_asignadas: asignatura.horas_asignadas,
        id_curso: asignatura.id_curso,
      },
    })
    commit('setResponse', data)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}

export async function removeAsignaturas({ commit, dispatch }, datos) {
  try {
    await axios({
      method: 'DELETE',
      url: `/asignatura/${datos.id_asignatura}`,
    })
    // actualizamos lista de asignaturas
    dispatch('fetchAsignaturasCurso', datos.id_curso)
  } catch (e) {
    commit('asignaturaError', e.response.data)
  }
}
