import axios from 'axios'

export async function fetchEquipo({ commit }, params) {
  try {
    const { id_curso, tipo } = params
    const { data } = await axios({
      method: 'GET',
      url: `pie/equipoaula/${id_curso}/${tipo}`,
    })
    commit('setEquipo', data)
    return { status: 'success', data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function fetchEquipoAll({ commit }, id_curso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `pie/equipoaula/${id_curso}`,
    })
    commit('setEquipo', data)
    return { status: 'success', data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function addEquipo({ dispatch }, equipo) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: 'pie/equipoaula',
      data: { ...equipo },
    })

    const params = {
      id_curso: equipo.id_curso,
      tipo: equipo.tipo,
    }
    await dispatch('fetchEquipo', params);
    return { ...data }

  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function updateEquipo({ dispatch }, equipo) {
  try {
    const { data } = await axios({
      method: 'PUT',
      url: `pie/equipoaula/${equipo.id}`,
      data: { ...equipo },
    })
    const params = {
      id_curso: equipo.id_curso,
      tipo: equipo.tipo,
    }
    await dispatch('fetchEquipo', params);
    return { ...data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function removeEquipo({ dispatch }, params) {
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: `pie/equipoaula/${params.id}`,
    })

    await dispatch('fetchEquipo', params);
    return { status: 'success', data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}
