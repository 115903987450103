import axios from 'axios'

export default async function realizadas() {
  return 0
}

export async function fetchRealizadas({ commit }, params) {
  try {
    const {
      idCurso
      , tipo
    } = params
    const { data } = await axios({
      method: 'GET',
      url: `registrocursoaccion/${idCurso}/${tipo}`,
    })
    commit('setRealizadas', data)
    return { status: 'success', data }
  } catch (e) {
    commit('realizadasError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function addRealizada({ commit }, apoyo) {
  try {
    commit('realizadasErrorNull')
    const { data } = await axios({
      method: 'POST',
      url: 'registroaccion',
      data: { ...apoyo },
    })
    commit('setResponse', data)
    return { ...data }
  } catch (e) {
    commit('realizadasError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function updateRealizada({ commit }, apoyo) {
  try {
    commit('realizadasErrorNull')
    const {data} = await axios({
      method: 'PUT',
      url: `registroaccion/${apoyo.id}`,
      data: { ...apoyo },
    })
    commit('setResponse', data)
    return { ...data }
  } catch (e) {
    commit('realizadasError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function removeRealizada({ commit, dispatch }, apoyo) {
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: `/registroaccion/${apoyo.id}`,
    })
    // actualizamos lista de realizadas
    dispatch('fetchRealizadas', apoyo.id_curso)
    return { ...data }
  } catch (e) {
    commit('realizadasError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}
