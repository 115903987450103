import axios from 'axios'

export default async function reuniones() {
  return 0
}

export async function fetchReuniones({ commit }, idCurso) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `reunion/${idCurso}`,
    })
    commit('setReuniones', data)
  } catch (e) {
    commit('reunionError', e.response.data)
    return { status: 'error', message: e.response.data }
  }
}

export async function addReunion({ dispatch }, reunion) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: 'reunion',
      data: { ...reunion },
    })
    await dispatch('fetchReuniones', reunion.id_cursos);
    return { ...data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function updateReunion({ dispatch }, reunion) {
  try {
    const { data } = await axios({
      method: 'PUT',
      url: `reunion/${reunion.id}`,
      data: { ...reunion },
    })
    console.log('data :', data)
    await dispatch('fetchReuniones', reunion.id_cursos);

    return { ...data }
  } catch (e) {
    return { status: 'error', message: e.response.data }
  }
}

export async function removeReunion({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/reunion/${id}`,
    })
  } catch (e) {
    commit('reunionError', e.response.data)
  }
}
